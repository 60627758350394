import React from 'react'
import theme from '@flock/shared-ui/src/theme/theme'
import { useMediaQuery } from '@mui/material'
import { navigate } from 'gatsby'
import PageWrapper from '../components/SharedComponents/PageWrapper'
import WhatIsFlockContentSection from '../components/WhatIsFlockComponents/WhatIsFlockContentSection'
import Hero from '../components/SharedComponents/Hero'
import Andy from '../images/andy.webp'
import { ONBOARDING_PATH } from '../routeConstants'
import { useRecordPageDuration } from '../components/utils'

const pageTitle = 'Flock | What is Flock'
const pageDescription = `Keep the benefits of owning a rental property and leave all of the responsibilities to us.`

export const Head = () => (
  <>
    <title>{pageTitle}</title>
    <meta title="title" property="og:title" content={pageTitle} />
    <meta
      name="description"
      property="og:description"
      content={pageDescription}
    />
  </>
)

const WhatIsFlock = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  useRecordPageDuration()

  return (
    <PageWrapper
      title={pageTitle}
      trackingName="what-is-flock"
      headerBackground="green1.main"
    >
      <Hero
        title="Easier than owning. Better than selling."
        description="Keep the benefits of owning a rental property and leave all of the responsibilities to us."
        backgroundColor="green1.main"
        titleColor="moneyGreen.main"
        descriptionColor="gray8.main"
        placeholder="Enter your property address"
        ctaType={isMobile ? 'button' : 'address'}
        ctaText="Get an offer"
        buttonTrackingConfig={{
          properties: {
            leadConversion: 'started',
          },
        }}
        src={Andy}
        leftMd={7}
        imgAlt="Landlord Relaxing"
        onClick={() => navigate(ONBOARDING_PATH)}
        frameProps={{
          label: 'A happy Flock family in',
          city: 'Denver',
          state: 'CO',
        }}
      />
      <WhatIsFlockContentSection />
    </PageWrapper>
  )
}

export default WhatIsFlock
