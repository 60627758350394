import { useMutation } from '@apollo/client'
import {
  AddressData,
  AddressTextField,
  BuildingFrame,
  TrackedButton,
  TrackedIconButton,
  TrackingConfig,
  useTracking,
} from '@flock/shared-ui'
import { PlayCircle } from '@mui/icons-material'
import Vimeo from 'react-vimeo-embed'
import { createLeadHelper, localStore, UserEventType } from '@flock/utils'
import {
  Typography,
  Grid,
  useTheme,
  CircularProgress,
  useMediaQuery,
  Box,
  Modal,
} from '@mui/material'
import { navigate } from 'gatsby'
import React, { useState } from 'react'
import {
  Core_TransactionType,
  LandingCreateLeadDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { GOOGLE_MAPS_API_KEY } from '../../constants'
import SectionLayout from './SectionLayout'

import VideoThumb from '../../images/stories-thumb.webp'
import { ONBOARDING_PATH } from '../../routeConstants'
import { getEventTokenUUID } from '../../utils/analytics'
import { WebinarModal } from './WebinarModal'

type HeroProps = {
  title: string
  description: string
  backgroundColor: string
  showVideo?: boolean
  titleColor?: string
  titleSize?: string
  descriptionColor?: string
  ctaType: 'button' | 'address' | 'none'
  ctaText: string
  placeholder?: string
  label?: string
  src: string
  width?: string
  height?: string
  imgAlt: string
  brokerUuid?: string
  onClick?: () => void
  to?: string
  leftMd?: number
  buttonTrackingConfig?: TrackingConfig

  frameProps?: {
    label: string
    city: string
    state: string
    badgeText?: React.ReactNode
  }
}

const Hero = (props: HeroProps) => {
  const {
    title,
    titleSize,
    description,
    backgroundColor,
    titleColor,
    descriptionColor,
    placeholder,
    label,
    ctaType,
    ctaText,
    src,
    width,
    height,
    imgAlt,
    brokerUuid,
    onClick,
    leftMd,
    to,
    frameProps,
    showVideo,
    buttonTrackingConfig,
  } = props
  const { track } = useTracking()
  const [addressData, setAddressData] = useState<AddressData | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [videoOpen, setVideoOpen] = useState<boolean>(false)
  const [createLead] = useMutation(LandingCreateLeadDocument)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const leftMargin = leftMd || 0

  const onSubmit = async () => {
    setLoading(true)
    track('hero-cta-click', {
      type: 'button',
      leadConversion: 'started',
      actionType: UserEventType.BUTTON_CLICK,
    })
    const { search } = window.location
    const params = new URLSearchParams(search)
    if (addressData?.streetAddress) {
      try {
        const leadData = {
          addressData,
          source: 'onboarding',
          transactionType: Core_TransactionType.TransactionTypeSingle_721,
          eventTokenUuid: getEventTokenUUID(),
        }
        const queryParams = localStore?.getItem('queryParams')
        let parsedQueryParams: any = {}
        if (queryParams) {
          parsedQueryParams = JSON.parse(queryParams)
        }
        const createLeadResponse = await createLeadHelper(
          leadData,
          parsedQueryParams,
          createLead
        )
        const newLeadUuid = createLeadResponse?.data.createLead.lead.uuid
        let path = `${ONBOARDING_PATH}?leadUuid=${newLeadUuid}`
        if (typeof Storage !== 'undefined') {
          localStore?.removeItem('step')
          localStore?.removeItem('flowData')
        }
        // carry forward all url params to be parsed in the same flow as onboarding flow
        params.forEach((key, value) => {
          path += `&${value}=${key}`
        })
        if (brokerUuid) {
          path += `&brokerUuid=${brokerUuid}`
        }
        navigate(path)
      } catch (e) {
        window.location.href = `https://form.jotform.com/tech700/flock-onboarding?address[addr_line1]=${addressData?.streetNumber}%20${addressData?.streetAddress}&address[city]=${addressData?.city}&address[state]=${addressData?.state}&address[postal]=${addressData?.zipcode}`
      }
    }
    setLoading(false)
  }

  const onKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      event.preventDefault()
      onSubmit()
    }
  }

  return (
    <SectionLayout name="hero-section" backgroundColor={backgroundColor}>
      <Grid
        container
        item
        md={showVideo ? leftMargin - 1 : leftMargin}
        sm={12}
        sx={{ gap: { md: '32px', sm: '32px', xs: '16px' } }}
        spacing={0}
      >
        <Typography variant={titleSize as any} color={titleColor}>
          {title}
        </Typography>
        <Typography variant="h4" color={descriptionColor}>
          {description}
        </Typography>
        {ctaType === 'address' ? (
          <>
            <Grid item xs={12}>
              <AddressTextField
                label={label}
                size={isMobile ? 'small' : 'large'}
                variant="outlined"
                ctaContent={
                  loading ? (
                    <Box
                      sx={{
                        width: {
                          md: '116px',
                          xs: '93px',
                        },
                      }}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <CircularProgress size="24px" color="white" />
                    </Box>
                  ) : (
                    ctaText
                  )
                }
                placeholder={placeholder}
                onKeyDown={onKeyDown}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(data: AddressData | null) => setAddressData(data)}
                onSubmit={onSubmit}
                googleMapsApiKey={GOOGLE_MAPS_API_KEY || ''}
              />
            </Grid>
            <Grid item xs={12} textAlign="start" mt="-24px">
              <WebinarModal />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <TrackedButton
                trackingConfig={buttonTrackingConfig}
                onClick={onClick}
                to={to}
                size="large"
              >
                {ctaText}
              </TrackedButton>
            </Grid>
            <Grid item xs={12} textAlign="left">
              <Box display="flex" justifyContent="flex-start">
                <WebinarModal />
              </Box>
            </Grid>
          </>
        )}
      </Grid>
      {showVideo && <Grid item xs={1} />}
      <Grid
        item
        md={12 - leftMargin}
        sx={{ display: { md: 'flex', sm: 'none', xs: 'none' } }}
        justifyContent="flex-end"
        alignItems="center"
      >
        {showVideo ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            padding="24px 24px 12px 24px"
            gap="12px"
            sx={{
              backgroundColor: '#ffffff',
            }}
          >
            <Box
              width="400px"
              height="224px"
              onClick={() => setVideoOpen(true)}
              position="relative"
              sx={{
                cursor: 'pointer',
              }}
            >
              <Box
                component="img"
                src={VideoThumb}
                width="400px"
                height="224px"
              />
              <TrackedIconButton
                onClick={() => setVideoOpen(true)}
                sx={{
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                  transform: 'translate(-50%, -50%)',
                  fontSize: '80px',
                }}
              >
                <PlayCircle fontSize="inherit" color="white" />
              </TrackedIconButton>
            </Box>

            <Typography variant="c1" textAlign="center">
              Don&apos;t just take it from us - <br />
              see why property owners love Flock
            </Typography>
          </Box>
        ) : (
          <>
            {frameProps ? (
              <BuildingFrame
                variant="standard"
                size="medium"
                src={src}
                {...frameProps}
              />
            ) : (
              <img alt={imgAlt} src={src} width={width} height={height} />
            )}
          </>
        )}
      </Grid>
      <Modal open={videoOpen} onClose={() => setVideoOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: 0,
            maxWidth: '100%',
          }}
        >
          <Vimeo
            video="708789562"
            width={isMobile ? '360' : '960'}
            height={isMobile ? '220' : '585'}
            autoplay
          />
        </Box>
      </Modal>
    </SectionLayout>
  )
}

export default Hero

Hero.defaultProps = {
  placeholder: '',
  label: '',
  brokerUuid: '',
  titleColor: '',
  descriptionColor: '',
  onClick: () => {},
  to: '',
  leftMd: 6,
  frameProps: undefined,
  titleSize: 'h1',
  showVideo: false,
  width: undefined,
  height: undefined,
  buttonTrackingConfig: undefined,
}
